import {Link} from 'react-router-dom'
import {Component} from 'react'
import Header from '../Header'
import BottomSection from '../BottomSection'
import About from '../About'
import ContactUs from '../ContactUs'
import './index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {TailSpin} from 'react-loader-spinner'
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player'
import backVideo from '../../images/background.mp4'
import crmain1 from '../../images/crmain1.jpeg'
import crmain2 from '../../images/crmain2.jpeg'
import crmain3 from '../../images/crmain3.jpeg'
import crmain4 from '../../images/crmain4.jpeg'
import homeadvantage1 from '../../images/homeadvantage1.jpg'
import homeadvantage2 from '../../images/homeadvantage2.jpg'
import homeadvantage3 from '../../images/homeadvantage3.jpg'
import homeadvantage4 from '../../images/homeadvantage4.jpg'

class Home extends Component{
  state={
    loading: true
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({ loading : !this.state.loading })
   }, 200);
  }

  loadingSpinner=()=>{
    return(
      <div className="products-loader-container">
            <TailSpin height="80" width="80" radius="9" color="green" ariaLabel="loading" />
        </div>
    )
  }

  render(){
    const{loading}=this.state
    AOS.init({duration:500})
    return(
      <>
        <Header />
        {loading? this.loadingSpinner() :
        <>
        <div className='main-home-video-cont'>
        <video
          className='home-video'
            playsInline
            autoPlay
            muted
            loop
           >
          <source
            src='https://res.cloudinary.com/drn0l89xj/video/upload/v1685177715/mgminfra%20images/background_yzmuib.mp4'
            type="video/mp4"
             />
        </video>
        <div className='main-home-video-text-cont'>
          <p className='main-home-video-para'>Contruction Company: <span className='main-home-video-mgm-para'>MG</span><span className='main-home-video-infra-para'>M Infra</span></p>
          <h1 className='main-home-video-head'>Your satisfaction is our goal. Your vision is our business.</h1>
        </div>
        <div className='main-home-video-button-cont'>
          <Link to='/about' className='link'><button className='main-home-video-button'>About Us</button></Link>
          <Link to='/services' className='link'><button className='main-home-video-button'>Services</button></Link>
        </div>
        </div>
        <div className='focus-cont'>
          <div className='focus-top-cont'>
            <img src='https://cdn0.iconfinder.com/data/icons/user-interface-2063/24/UI_Essential_icon_expanded_3-32-256.png' className='focus-img'/>
            <h1 className='focus-head'>We focus on mutual success, everyone wins.</h1>
          </div>
          <p>“Our mission is to focus on our customers’ needs in order to provide the highest quality construction services. Our staff is committed to excellence, and they will ensure our clients receive added value in all their dealings with MGM Infra.” </p>
          <p>We are committed to accomplishing every project in a manner that represents the pride and passion we feel for our industry. We want to help make your dream build a reality.</p>
        </div>
            <div className="home-container">
                <video
                    className='home-video'
                      playsInline
                      autoPlay
                      muted
                      loop
                      >
                  <source
                    src="https://mdbootstrap.com/img/video/Lines.mp4"
                    type="video/mp4"
                      />
                  </video>
                <div className="home-content">
                  <h1 className="home-heading bestpartner-main-head" data-aos='slide-up'>The Best Partner</h1>
                  <img
                    src="https://www.creherald.com/wp-content/uploads/2022/02/resdiential-real-estate-partnership-min.jpg"
                    alt="clothes to be noticed"
                    className="home-mobile-img" />
                    <h1 className="home-heading bestpartner-second-head" data-aos='slide-up'>We strive to maintain the highest standards while exceeding client`s expectations.</h1>
                  <p className="home-description" data-aos='slide-up'>
                  At MGM Infra we have sustained success through the strength and expertise of our people, providing innovative construction solutions to complex infrastructure projects across PAN India, especially in Telangana. Whatever your project is, we have the expertise to do it through our various contracting modes including general contracting/bid-build, CM/GC, design-build, design-bid-build, and public-private partnerships. Our greatest competitive advantage is the expertise and passion of our people. We are motivated to achieve the elements needed to provide project success and build structures and roadways that will leave a lasting impression for our clients, our society, and our communities.
                  </p>
                  <h1 className="home-heading bestpartner-second-head" data-aos='slide-up'>DELIVERING INNOVATION EVERY STEP OF THE WAY.</h1>
                  <p className="home-description" data-aos='slide-up'>
                  MGM Infra is a full-service contractor and industry leader, with the people, expertise, and financial strength to execute and deliver both large and small projects- successfully. We have earned our reputation as one of the most experienced, innovative, and quality-driven contractors in India, with over 40 years of service in the civil construction industry.
                  </p>
                </div>
                <img
                  src="https://www.ilsiengineering.com/wp-content/uploads/2016/10/civil-engineering-firms.jpg"
                  alt="dresses to be noticed"
                  className="home-desktop-img" data-aos='zoom-out-up' />
              </div>
              <section>
                <div className='ref-main-cont'>
                  <div>
                    <h1 className='ref-head-main'>OUR REFERENCE SITES</h1>
                  </div>
                  <p className='ref-para'>Sports Facilities</p>
                  <div className='ref-imgs-cont'>
                    <div className='ref-card card1'>
                      <h1 className='ref-text'>FootBall & Cricket Court (Sports Villa)</h1>
                    </div>
                    <div className='ref-card card2'>
                      <h1 className='ref-text'>Tennis Court (Gowrah)</h1>
                    </div>
                    <div className='ref-card card3'>
                      <h1 className='ref-text'>Badminton Court (Sri SK Golden Racket)</h1>
                    </div>
                    <div className='ref-card card4'>
                      <h1 className='ref-text'>Basket Ball Court (NCC Urban One)</h1>
                    </div>
                    <div className='ref-card card5'>
                      <h1 className='ref-text'>Cricket Practice Net (DilshukNagar Public School)</h1>
                    </div>
                    <div className='ref-card card6'>
                      <h1 className='ref-text'>Landscaping Turf (Ramky)</h1>
                    </div>
                  </div>
                </div>
              <div className='ourservices-home-cont'>
              <div className='carousel-cont'>
                <Carousel infiniteLoop={true} interval={1500} emulateTouch={true} transitionTime={250} autoPlay={100} showThumbs={false} showArrows={true} swipeable={true}>
                    <div>
                        <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177710/mgminfra%20images/crmain1_vai6xe.jpg' className='carousel-image'/>
                    </div>
                    <div>
                        <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177710/mgminfra%20images/crmain2_bunbu2.jpg' className='carousel-image' />
                    </div>
                    <div>
                        <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177710/mgminfra%20images/crmain3_suyohm.jpg' className='carousel-image'/>
                    </div>
                </Carousel>
            </div>
            <div className='ourservices-home-text-cont'>
              <h1 className='ourservices-home-main-head'>OUR SERVICES</h1>
              <div className='ourservices-home-cards-cont'>
                <div className='ourservices-home-card'>
                  <div className='layer'></div>
                  <div className='content'>
                    <h1 className='ourservices-home-card-head'>Design</h1>
                    <p className='ourservices-home-card-para'>We ensure the proper design for every project to build sustainable and quality construction</p>
                  </div>
                </div>
                <div className='ourservices-home-card'>
                  <div className='layer'></div>
                  <div className='content'>
                    <h1 className='ourservices-home-card-head'>Construction</h1>
                    <p className='ourservices-home-card-para'>We execute and build the structure with high quality standards</p>
                  </div>
                </div>
                <div className='ourservices-home-card'>
                  <div className='layer'></div>
                  <div className='content'>
                    <h1 className='ourservices-home-card-head'>Maintenance</h1>
                    <p className='ourservices-home-card-para'>We maintain facilities and systems operational and in good working order.</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className='bg-about'>
              <h1 className='card-head'>Advantage of MGM Infra</h1>
              <p className='advantage-para'>We understand the importance of on-time delivery, A project can only be a success if it is delivered on time. Proper planning, budgeting, scheduling, of raw materials, logistics and manpower help us to achieve on-time delivery and execution of the project within the given time frame.</p>
            <div className='main-card-cont'>
                <div className='card' data-aos='fade-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177711/mgminfra%20images/homeadvantage1_xyd9el.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>BUILDING</p>
                        <p className='card-para'>We build safely, with high quality, and above all – we listen to people. We design, build, finance, operate, and manage projects and programs that unlock opportunities.</p>
                    </div>
                </div>
                <div className='card' data-aos='zoom-out-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177711/mgminfra%20images/homeadvantage2_o3jvzf.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>OUR VISIONS</p>
                        <p className='card-para'>Our vision is to be the most trusted partner for our customers by consistently delivering excellence – bringing together the knowledge, expertise, and skills of our people.</p>
                    </div>
                </div>
                <div className='card' data-aos='zoom-out-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177711/mgminfra%20images/homeadvantage3_kmc9ks.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>BEST SOLUTIONS</p>
                        <p className='card-para'>MGM Infra Solutions is a solution provider for building industry customers in (Telangana) southern India and other states as well.</p>
                    </div>
                </div>
                <div className='card' data-aos='zoom-out-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/homeadvantage4_hcyj6v.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>PROPERTY DEVELOPMENT</p>
                        <p className='card-para'>Our organization is professional and cost-effective, we create competitively added value for our customers..</p>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <section>
        <div className='bg-features'>
            <div>
                <p className='features-head-para' data-aos='slide-right'>Why choose us?</p>
                <p className='features-head-main' data-aos='slide-right'>‘Best in class’ service ensured</p>
            </div>
            <div className='features-right-cont'>
                <div className='features-sale' data-aos='slide-up'>
                    <img src='https://cdn4.iconfinder.com/data/icons/basic-ui-2-line/32/clock-time-ticker-times-hour-256.png' className='img-features'/>
                    <p className='features-head'>Projects done on time</p>
                    <p className='features-para'>Our team will do all your projects on time ad well within the budget. You can be safe and certain about that with our company.</p>
                </div>
                <div className='features-sale' data-aos='slide-up'>
                    <img src='https://cdn4.iconfinder.com/data/icons/multimedia-75/512/multimedia-01-256.png' className='img-features'/>
                    <p className='features-head'>Always available</p>
                    <p className='features-para'>We’re there for you anytime you need us. Contact us for a consultation, estimate or any question you might have about your project or our work.</p>
                </div>
                <div className='features-sale' data-aos='slide-up'>
                    <img src='https://cdn0.iconfinder.com/data/icons/business-set-1-6/128/b-21-256.png' className='img-features'/>
                    <p className='features-head'>Professional and responsible</p>
                    <p className='features-para'>You can always lean onto ReConstruct. Our team of professionals will help you turn your dream home or flat into a reality fast.</p>
                </div>
            </div>
        </div>
        </section>
        <BottomSection/>
            <a
              href="https://wa.me/+919618670351"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
            </>
        }
  </>
  )
  }
}

export default Home
