import './index.css'
import pro18 from '../../images/pro18.jpeg'
import pro19 from '../../images/pro19.jpeg'
import pro20 from '../../images/pro20.jpeg'
import pro21 from '../../images/pro21.jpeg'
import pro22 from '../../images/pro22.jpeg'

const CommercialProjects=()=>{
    return(
        <div className='sports-child-main-cont'>
            <div className='allpro-main-cont'>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703492/mgminfra%20images/pro18.3962385107e6684e3222_rd24q1.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703492/mgminfra%20images/pro19.0a6afa4f9db4470c9625_kxcr0i.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703493/mgminfra%20images/pro20.02cf42ac012d05488baf_jqmjat.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703494/mgminfra%20images/pro21.87bf814694744a5a1f6e_sjnp7l.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703500/mgminfra%20images/pro22.ac45472630ae0bbb1710_cpkoz2.jpg' className='allpro-img'/>
            </div>
        </div>
    )
}

export default CommercialProjects