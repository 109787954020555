import './index.css'
import pro1 from '../../images/pro1.jpeg'
import pro2 from '../../images/pro2.jpeg'
import crmain2 from '../../images/crmain2.jpeg'
import pro4 from '../../images/pro4.jpeg'
import pro5 from '../../images/pro5.jpeg'
import pro6 from '../../images/pro6.jpeg'
import refsite1 from '../../images/refsite1.jpeg'
import refsite2 from '../../images/refsite2.jpeg'
import refsite3 from '../../images/refsite3.jpeg'
import refsite4 from '../../images/refsite4.jpeg'
import refsite5 from '../../images/refsite5.jpeg'
import refsite6 from '../../images/refsite6.jpeg'
import pro7 from '../../images/pro7.jpeg'
import pro8 from '../../images/pro8.jpeg'
import pro9 from '../../images/pro9.jpeg'
import pro10 from '../../images/pro10.jpeg'
import pro11 from '../../images/pro10.jpeg'
import pro12 from '../../images/pro12.jpeg'
import pro13 from '../../images/pro13.jpeg'
import pro14 from '../../images/pro14.jpeg'

const SportsFacilities=()=>{
    return(
        <>
        <div className='sports-child-main-cont'>
            <h1 className='sports-child-main-head'>Children Play Area</h1>
            <hr className='hr' align="left"></hr>
            <div className='allpro-main-cont'>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/pro1_uesk7q.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/pro2_nscw0o.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro4_kdcz78.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177710/mgminfra%20images/crmain2_bunbu2.jpg' className='allpro-img'/>
            </div>
            <h1 className='sports-child-main-head'>Designs of Sport Facilities</h1>
            <hr className='hr' align="left"></hr>
            <div className='allpro-main-cont'>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro7_mvjwqo.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177714/mgminfra%20images/pro8_rjyz6v.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177714/mgminfra%20images/pro9_x6d7we.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177714/mgminfra%20images/pro10_wkduaa.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177714/mgminfra%20images/pro11_fhxolb.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177714/mgminfra%20images/pro12_rjzjyu.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177715/mgminfra%20images/pro13_ljnlc8.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177715/mgminfra%20images/pro14_eh7yyp.jpg' className='allpro-img'/>
            </div>
        </div>
        <h1 className='sports-child-main-head'>Others</h1>
        <hr className='hr' align="left"></hr>
        <div className='allpro-main-cont'>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro5_glfhzh.jpg' className='allpro-img'/>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro6_rqtp1t.jpg' className='allpro-img'/>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite1_ing8k1.jpg' className='allpro-img'/>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite2_r2lrat.jpg' className='allpro-img'/>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite3_ozsfso.jpg' className='allpro-img'/>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite4_buuhy7.jpg' className='allpro-img'/>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite5_aev6ze.jpg' className='allpro-img'/>
            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite6_gom2sc.jpg' className='allpro-img'/>
        </div>
        </>
    )
}

export default SportsFacilities