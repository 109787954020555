import './index.css'
import ReactPlayer from 'react-player'
import pro16 from '../../images/pro16.jpeg'
import pro17 from '../../images/pro17.jpeg'
import pro23 from '../../images/pro23.jpeg'
import pro24 from '../../images/pro24.mp4'

const ResidentialProjects=()=>{
    return(
        <div className='sports-child-main-cont'>
            <div className='allpro-main-cont'>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177715/mgminfra%20images/pro16_bn7ray.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177715/mgminfra%20images/pro17_bi1q5z.jpg' className='allpro-img'/>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725702882/mgminfra%20images/pro23.b51698e74aa48901a3f0_erhvlp.jpg' className='allpro-img'/>
                <div className='player-res'>
                    <ReactPlayer
                        url='https://res.cloudinary.com/drn0l89xj/video/upload/v1725703184/mgminfra%20images/pro24.83eaf258161647aa8c26_yodlvi.mp4'
                        width='100%'
                        height='100%'
                        controls={true}
                        />
                </div>
            </div>
        </div>
    )
    }

export default ResidentialProjects