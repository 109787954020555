import './index.css'
import {Link} from 'react-router-dom'

const BottomSection=()=>{
    return(
        <><div className='home-main-contactus-cont'>
            <div className='home-main-contactus-text-cont'>
                <h1 className='home-main-contactus-head'>Ready to work together?</h1>
                <p className='home-main-contactus-para'>Whether you have a project in mind and you’re looking for a reliable construction partner or you’re looking to take the next step in your career, we want to hear from you!</p>
                <Link to='/contact' className='link'><button className='home-main-contactus-button'>BUILD A PROJECT WITH US</button></Link>
            </div>
        </div>
        <div className='bottom-sec-cont'>
        <div className='bottom-layer'>
        <div className='home-main-bottom-section'>
                <div className='home-main-bottom-section-about-cont'>
                    <h1 className='home-main-bottom-section-about-head'>About Us</h1>
                    <hr className='hr' align="left"></hr>
                    <p className='home-main-bottom-section-para'>MGM Infra is a full-service contractor and industry leader, with the people, expertise, and financial strength to execute and deliver both large and small projects- successfully. We have earned our reputation as one of the most experienced, innovative, and quality-driven contractors india in the civil construction industry.</p>
                </div>
                <div>
                    <h1 className='home-main-bottom-section-about-head'>Menu</h1>
                    <hr className='hr' align="left"></hr>
                    <Link to='/about' className='home-main-bottom-section-para'><p>About Us</p></Link>
                    <Link to='/services' className='home-main-bottom-section-para'><p>Services</p></Link>
                    <Link to='/projects' className='home-main-bottom-section-para'><p>Projects</p></Link>
                    <Link to='/photogallery' className='home-main-bottom-section-para'><p>Photo Gallery</p></Link>
                    <Link to='/contact' className='home-main-bottom-section-para'><p>Contact Us</p></Link>
                </div>
                <div className='bottom-sec-text-cont'>
                    <h1 className='home-main-bottom-section-about-head' >Area of Expertise</h1>
                    <hr className='hr' align="left"></hr>
                    <p className='home-main-bottom-section-para'>Designing</p>
                    <p className='home-main-bottom-section-para'>Construction of Residential Bulidings</p>
                    <p className='home-main-bottom-section-para'>Construction of Sport Facilities</p>
                </div>
                <div>
                    <h1 className='home-main-bottom-section-about-head'>Contact</h1>
                    <hr className='hr' align="left"></hr>
                    <p className='home-main-bottom-section-para'>Contact No: +919618670351</p>
                    <p className='home-main-bottom-section-para'>Email: <a href="mailto: mgminfragmail.com">mgminfra22@gmail.com</a></p>
                </div>
                <div>
                    <h1 className='home-main-bottom-section-about-head'>Follow us</h1>
                    <hr className='hr' align="left"></hr>
                    <div className='contact-img-cont'>
                        <a href='https://www.linkedin.com/company/mgm-infra/' target='_blank'><img src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-256.png' className='follow-img' /></a>
                        <a href='https://www.instagram.com/mgminfra786/' target='_blank'><img src='https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-256.png' className='follow-img' /></a>
                        <a href='https://www.facebook.com/sameersohail143' target='_blank'><img src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-facebook_-256.png' className='follow-img' /></a>
                        <a href='https://wa.me/+919618670351' target='_blank'><img src='https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo-whatsapp-512.png' className='follow-img' /></a>
                    </div>
                </div>
            </div>
            <hr className='hr-bottom'></hr>
            <div className='bottom-credit-cont'>
                <p className='bottom-credit-para'>© 2023 MGM Infra Company - All Rights Reserved | Website By : <a href='https://github.com/mdsameersohail' target='_blank'>Md Sameer Sohail</a></p>
            </div>
            </div>
            </div>
            </>
    )
}

export default BottomSection