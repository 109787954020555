import './index.css'
import Header from '../Header'
import BottomSection from '../BottomSection'
import photo from '../../images/photosample.jpg'
import {Component} from 'react'
import {TailSpin} from 'react-loader-spinner'


class PhotoGallery extends Component{
    state={
        loading: true
      }
    
      componentDidMount(){
        setTimeout(() => {
          this.setState({ loading : !this.state.loading })
       }, 200);
      }
    
      loadingSpinner=()=>{
        return(
          <div className="products-loader-container">
                <TailSpin height="80" width="80" radius="9" color="green" ariaLabel="loading" />
            </div>
        )
      }

    render(){
        const{loading}=this.state
        return(
        <>
        <Header/>
        {loading? this.loadingSpinner() :
        <>
        <div className='photogallery-main-bg-cont'>
            <div className='photogallery-main-card-cont'>
                <h1 className='photogallery-main-heading'>Our Team</h1>
                <hr className='hr' align="left"></hr>
                <div className='photogallery-main-team-cont'>
                    <div className='photogallery-main-person-cont'>
                        <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/photosample_lxikqd.jpg' className='photogallery-main-img'/>
                        <div className='photogallery-main-team-text-cont'>
                            <h1 className='photogallery-main-person-head'>sameer</h1>
                            <p>Civil Engineer</p>
                        </div>
                    </div>
                    <div className='photogallery-main-person-cont'>
                        <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/photosample_lxikqd.jpg' className='photogallery-main-img'/>
                        <div className='photogallery-main-team-text-cont'>
                            <h1 className='photogallery-main-person-head'>sameer</h1>
                            <p>Civil Engineer</p>
                        </div>
                    </div>
                    <div className='photogallery-main-person-cont'>
                        <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/photosample_lxikqd.jpg' className='photogallery-main-img'/>
                        <div className='photogallery-main-team-text-cont'>
                            <h1 className='photogallery-main-person-head'>sameer</h1>
                            <p>Civil Engineer</p>
                        </div>
                    </div>
                </div>
                <h1 className='photogallery-main-heading'>Portfolio</h1>
                <hr className='hr' align="left"></hr>
            </div>
        </div>
        <BottomSection/>
        <a
              href="https://wa.me/+919618670351"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </>
        }
    </>
    )
    }
}

export default PhotoGallery