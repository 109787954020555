import {Link, withRouter,NavLink} from 'react-router-dom'

import Cookies from 'js-cookie'
import mylogo from '../../images/mgmlogo.jpg'

import './index.css'

const Header = props => {
  const iconCilicked=()=>{
    navIcon.classList.toggle("fa-xmark")
    menu.classList.toggle('menu-visible')
  }
  return (
    <nav className="nav-header">
      <div className="nav-content">
        <Link to="/" className='logo-div'>
          <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/mgmlogo_yu21wr.jpg' className='mgmLogo'/>
          <div className='logo-text-cont'>
          <h1 className='logo-head'>MG<span className='M-color'>M Infra</span></h1>
          <p className='logo-para'>One Stop Solution for your Infrastructure Need....</p>
          </div>
        </Link>
        <ul className="nav-menu">
          <li>
            <NavLink  exact to="/" className="nav-link" activeClassName="nav-link-active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" className="nav-link" activeClassName="nav-link-active">
              About Us
            </NavLink>
          </li>
          <li className='dropdown'>
            <NavLink to="/services" className="nav-link" activeClassName="nav-link-active">
              Services
              <div className="dropdown-content">
                  <Link id="RouterNavLink"  to="/services">Design</Link>
                  <Link id="RouterNavLink"  to="/services">Construction</Link>
                  <Link id="RouterNavLink"  to="/services">Maintenance</Link>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" className="nav-link" activeClassName="nav-link-active">
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink to="/photogallery" className="nav-link" activeClassName="nav-link-active">
              Photo Gallery
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className="nav-link" activeClassName="nav-link-active">
              Contact Us
            </NavLink>
          </li>
        </ul>
        <div className="nav-menu-mobile">
         <button className='font-bar-icon' onClick={iconCilicked}><i className="fa-solid fa-bars" id='navIcon'></i></button>
      </div>
      </div>
      <ul className='menu' id='menu'>
        <div className='nav-drop-cont'>
        <li><Link to='/'>Home</Link></li>
        </div>
        <div className='nav-drop-cont'>
        <li><Link to='/about'>About Us</Link></li>
        </div>
        <div className='nav-drop-cont'>
        <li><Link to='/services'>Services</Link></li>
        </div>
        <div className='nav-drop-cont'>
        <li><Link to='/projects'>Projects</Link></li>
        </div>
        <div className='nav-drop-cont'>
        <li><Link to='/photogallery'>Photo Gallery</Link></li>
        </div>
        <div className='nav-drop-cont'>
        <li><Link to='/contact'>Contact Us</Link></li>
        </div>
      </ul>
    </nav>
  )
}
export default withRouter(Header)