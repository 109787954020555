import './index.css'
import ReactPlayer from 'react-player'
import pro1 from '../../images/pro1.jpeg'
import pro2 from '../../images/pro2.jpeg'
import pro3 from '../../images/pro3.jpeg'
import pro4 from '../../images/pro4.jpeg'
import pro5 from '../../images/pro5.jpeg'
import pro6 from '../../images/pro6.jpeg'
import refsite1 from '../../images/refsite1.jpeg'
import refsite2 from '../../images/refsite2.jpeg'
import refsite3 from '../../images/refsite3.jpeg'
import refsite4 from '../../images/refsite4.jpeg'
import refsite5 from '../../images/refsite5.jpeg'
import refsite6 from '../../images/refsite6.jpeg'
import pro16 from '../../images/pro16.jpeg'
import pro17 from '../../images/pro17.jpeg'
import pro18 from '../../images/pro18.jpeg'
import pro19 from '../../images/pro19.jpeg'
import pro20 from '../../images/pro20.jpeg'
import pro21 from '../../images/pro21.jpeg'
import pro22 from '../../images/pro22.jpeg'
import pro23 from '../../images/pro23.jpeg'
import pro24 from '../../images/pro24.mp4'

const AllProjects=()=>{
    return(
        <div className='allpro-main-cont'>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/pro1_uesk7q.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/pro2_nscw0o.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro3_hpsalv.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro4_kdcz78.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro5_glfhzh.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177713/mgminfra%20images/pro6_rqtp1t.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite1_ing8k1.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite2_r2lrat.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite3_ozsfso.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite4_buuhy7.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite5_aev6ze.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177716/mgminfra%20images/refsite6_gom2sc.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177715/mgminfra%20images/pro16_bn7ray.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177715/mgminfra%20images/pro17_bi1q5z.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703492/mgminfra%20images/pro18.3962385107e6684e3222_rd24q1.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703492/mgminfra%20images/pro19.0a6afa4f9db4470c9625_kxcr0i.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703493/mgminfra%20images/pro20.02cf42ac012d05488baf_jqmjat.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703494/mgminfra%20images/pro21.87bf814694744a5a1f6e_sjnp7l.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725703500/mgminfra%20images/pro22.ac45472630ae0bbb1710_cpkoz2.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className="content_img">
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1725702882/mgminfra%20images/pro23.b51698e74aa48901a3f0_erhvlp.jpg' className='showall-img'/>
                <div>Image 1 Text</div>
            </div>
            <div className='palyer'>
                <ReactPlayer
                    url='https://res.cloudinary.com/drn0l89xj/video/upload/v1725703184/mgminfra%20images/pro24.83eaf258161647aa8c26_yodlvi.mp4'
                    width='100%'
                    height='100%'
                    controls={true}
                    />
            </div>
        </div>
    )
}

export default AllProjects