import './index.css'
import {Component} from 'react'
import BottomSection from '../BottomSection'
import {TailSpin} from 'react-loader-spinner'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Header from '../Header'
import aboutpng from '../../images/aboutpng.png'
import homeadvantage1 from '../../images/homeadvantage1.jpg'
import homeadvantage2 from '../../images/homeadvantage2.jpg'
import homeadvantage3 from '../../images/homeadvantage3.jpg'
import homeadvantage4 from '../../images/homeadvantage4.jpg'

class About extends Component{
    state={
        aboutLoading: true
      }

    componentDidMount(){
        setTimeout(() => {
          this.setState({ aboutLoading : !this.state.aboutLoading })
       }, 200);
    }
    
    loadingSpinner=()=>{
        return(
          <div className="products-loader-container">
                <TailSpin height="80" width="80" radius="9" color="green" ariaLabel="loading" />
            </div>
        )
    }

    render(){
        const{aboutLoading}=this.state
        AOS.init({duration:1000})
    return(
        <>
        <Header/>
        {aboutLoading? this.loadingSpinner() :
        <>
        <section>
            <div className='aboutus-main-top-cont'>
                <div className='aboutus-text-cont'>
                    <h1 className='aboutus-text-top-head'>About Us</h1>
                    <hr className='hr-line'/>
                    <h1 className='aboutus-main-top-second-head'>Company MGM Infra is a team of experienced construction professionals specializing in commercial and industrial construction.</h1>
                    <p className='aboutus-main-top-para'>The list of our services covers the entire spectrum of construction production, from turnkey construction to very complex, specialized services such as concrete foundations, steel structures and roofing, BK-TSENTRBUD are professionals in the design and construction business. The best companies choose us as a general contractor because our employees adhere to the basic principles of safety, quality and work efficiency. Highly qualified professionals are ready to help you complete your project on time and on budget.</p>
                    <p className='aboutus-main-top-last-head'>We will be happy to discuss your needs and offer a free consultation based on your requirements and our many years of experience.</p>
                </div>
                <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177710/mgminfra%20images/aboutpng_lmbfrn.png' className='aboutus-img'/>
            </div>
        </section>
        <section>
        <div className='bg-about'>
            <h1 className='card-head'>GOALS OF OUR COMPANY</h1>
            <div className='goals-main-cont'>
                <h1 className='goals-main-head'>To provide our clients with a high level of quality construction services at fair and competitive prices.</h1>
                <div className='goals-main-cont-right'>
                    <h1 className='goals-main-head-right'>Professional Responsibility</h1>
                    <p className='goals-main-para'>Maintain a high level of professionalism, honesty and fair dealing with our suppliers, subcontractors and professional partners.</p>
                    <h1 className='goals-main-head-right'>Dedication to customers</h1>
                    <p className='goals-main-para'>Building long-term relationships with clients so that you, like us, are successful.</p>
                </div>
            </div>
            <div className='main-card-cont'>
                <div className='card' data-aos='fade-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177711/mgminfra%20images/homeadvantage1_xyd9el.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>BUILDING</p>
                        <p className='card-para'>We build safely, with high quality, and above all – we listen to people. We design, build, finance, operate, and manage projects and programs that unlock opportunities.</p>
                    </div>
                </div>
                <div className='card' data-aos='zoom-out-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177711/mgminfra%20images/homeadvantage2_o3jvzf.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>OUR VISIONS</p>
                        <p className='card-para'>Our vision is to be the most trusted partner for our customers by consistently delivering excellence – bringing together the knowledge, expertise, and skills of our people.</p>
                    </div>
                </div>
                <div className='card' data-aos='zoom-out-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177711/mgminfra%20images/homeadvantage3_kmc9ks.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>BEST SOLUTIONS</p>
                        <p className='card-para'>MGM Infra Solutions is a solution provider for building industry customers in (Telangana) southern India and other states as well.</p>
                    </div>
                </div>
                <div className='card' data-aos='zoom-out-up'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/homeadvantage4_hcyj6v.jpg' className='main-img'/>
                    <div className='card-para-cont'>
                        <p className='card-para-head'>PROPERTY DEVELOPMENT</p>
                        <p className='card-para'>Our organization is professional and cost-effective, we create competitively added value for our customers..</p>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <section>
        <div className='bg-features'>
            <div>
                <p className='features-head-para' data-aos='slide-right'>Why choose us?</p>
                <p className='features-head-main' data-aos='slide-right'>‘Best in class’ service ensured</p>
            </div>
            <div className='features-right-cont'>
                <div className='features-sale' data-aos='slide-up'>
                    <img src='https://cdn4.iconfinder.com/data/icons/basic-ui-2-line/32/clock-time-ticker-times-hour-256.png' className='img-features'/>
                    <p className='features-head'>Projects done on time</p>
                    <p className='features-para'>Our team will do all your projects on time ad well within the budget. You can be safe and certain about that with our company.</p>
                </div>
                <div className='features-sale' data-aos='slide-up'>
                    <img src='https://cdn4.iconfinder.com/data/icons/multimedia-75/512/multimedia-01-256.png' className='img-features'/>
                    <p className='features-head'>Always available</p>
                    <p className='features-para'>We’re there for you anytime you need us. Contact us for a consultation, estimate or any question you might have about your project or our work.</p>
                </div>
                <div className='features-sale' data-aos='slide-up'>
                    <img src='https://cdn0.iconfinder.com/data/icons/business-set-1-6/128/b-21-256.png' className='img-features'/>
                    <p className='features-head'>Professional and responsible</p>
                    <p className='features-para'>You can always lean onto ReConstruct. Our team of professionals will help you turn your dream home or flat into a reality fast.</p>
                </div>
            </div>
        </div>
        </section>
        <BottomSection/>
        <a
              href="https://wa.me/+919618670351"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </>
        }
    </>
    )
}
}

export default About