import './index.css'
import { Component } from 'react'
import {Link} from 'react-router-dom'
import Header from '../Header'
import React from 'react'
import axios from 'axios'
import AOS from 'aos'
import 'aos/dist/aos.css'
import mylogo from '../../images/mgmlogo.jpg'
import {TailSpin} from 'react-loader-spinner'


class ContactUs extends Component{
    state={
        yourname:'',
        youremail:'',
        yournumber:'',
        complainMessage:'',
        complainSuccess:false,
        contactLoading:true
    }

    componentDidMount(){
        setTimeout(() => {
          this.setState({ contactLoading : !this.state.contactLoading })
       }, 200);
    }
    
    loadingSpinner=()=>{
        return(
          <div className="products-loader-container">
                <TailSpin height="80" width="80" radius="9" color="green" ariaLabel="loading" />
            </div>
        )
    }

    onChangeYourName=(event)=>{
        this.setState({yourname:event.target.value})
    }
    onChangeYourNumber=(event)=>{
        this.setState({yournumber:event.target.value})
    }

    onChangeYourEmail=(event)=>{
        this.setState({youremail:event.target.value})
    }

    onChangeComplain=(event)=>{
        this.setState({complainMessage:event.target.value})
    }

    contactFormSubmit=(event)=>{
        event.preventDefault()
        const{yourname,yournumber,youremail,complainMessage}=this.state
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post('https://formsubmit.co/ajax/mdsameersohail786@gmail.com', {
            name: yourname,
            mobileNumber:yournumber,
            email: youremail,
            message: complainMessage
        })
        this.setState({complainSuccess:true})
    }

    emailSendSuccess=()=>{
        this.setState({yourname:'', yournumber:'', youremail:'', complainMessage:'', complainSuccess:false})

    }
    render(){
        const{yourname,yournumber,youremail,complainMessage,complainSuccess,contactLoading}=this.state
        AOS.init({duration:2000})
        const emailSuccess=(
            <div className='email-sent-cont'>
                <img src='https://purepng.com/public/uploads/large/purepng.com-ok-iconyestickiconokcorrectokayconfirm-4115201939019kiil.png' className='contact-img'/>
                <p className='email-sent-para'>Email sent Successful <br></br><span><a href='#ContactUS' onClick={this.emailSendSuccess}>Click Here</a> to send another response</span></p>
            </div>
        )
        return(
            <>
            <Header/>
            {contactLoading? this.loadingSpinner() :
            <>
            <div className='contact-bg-cont'>
                <h1 className='contact-main-head'>Contact Us</h1>
                <div className='address-top-cont'>
                    <div className='address-top-text-cont'>
                        <h1 className='address-top-head'>Address:</h1>
                        <hr className='hr' align="left"></hr>
                        <p className='address-top-para'>Mohinpura, Siddipet, Telangana, 502103<br></br>
                        GSTIN: 36FJMPS2820C1Z6<br></br>
                        State: 36-Telangana.
                        </p>
                        <h1 className='address-top-head'>Email:</h1>
                        <hr className='hr' align="left"></hr>
                        <p className='address-top-para'><a href="mailto: mgminfra22@gmail.com">mgminfra22@gmail.com</a></p>
                        <h1 className='address-top-head'>Phone:</h1>
                        <hr className='hr' align="left"></hr>
                        <p className='address-top-para'>+919618670351</p>
                    </div>
                    <div className='map-cont'>
                    <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8762.887574293092!2d78.8364966627281!3d18.103943274444262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcc92b2b5edb805%3A0x498ecfa22f1e8ce4!2sSiddipet%2C%20Telangana!5e0!3m2!1sen!2sin!4v1685008725434!5m2!1sen!2sin" height="450"  loading="lazy"></iframe>
                    </div>
                </div>
                <div className='contact-bottom-cont'>
                    {complainSuccess? emailSuccess:<div className='contact-form-cont' data-aos='fade' id='contactForm'>
                        <form type='submit' onSubmit={this.contactFormSubmit}>
                            <h1 className='contact-main-head'>Get in touch</h1>
                            <div className='form-group'>
                                <input type='text' id='name' name='name' value={yourname} onChange={this.onChangeYourName} required/><label htmlFor='email'>Your Name</label>
                            </div>
                            <div className='form-group'>
                                <input type='number' id='number' name='number' value={yournumber} onChange={this.onChangeYourNumber} required/><label htmlFor='password'>Mobile Number</label>
                            </div>
                            <div className='form-group'>
                                <input type='email' id='email' name='email' value={youremail} onChange={this.onChangeYourEmail} required/><label htmlFor='password'>Your Email</label>
                            </div>
                            <div className='contact-message-cont'>
                                <p className='contact-para'>Message</p>
                                <textarea name="message" rows="5" cols="30" value={complainMessage} onChange={this.onChangeComplain}/>
                            </div>
                            <div className='contact-button-cont'>
                                <button className='contact-button'>Submit</button>
                            </div>
                        </form>
                    </div>}
                    
                    <div className='contact-right-card'>
                        <Link to="/" className='logo-div'>
                            <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177712/mgminfra%20images/mgmlogo_yu21wr.jpg' className='mgmLogo'/>
                            <div className='logo-text-cont'>
                                <h1 className='logo-head'>MG<span className='M-color'>M Infra</span></h1>
                                <p className='logo-para'>One Stop Solution for your Infrastructure Need....</p>
                            </div>
                        </Link>
                        <p className='contact-des-para'>MGM Infra is a full-service contractor and industry leader, with the people, expertise, and financial strength to execute and deliver both large and small projects- successfully. We have earned our reputation as one of the most experienced, innovative, and quality-driven contractors india in the civil construction industry.</p>
                        <div className='contact-img-cont'>
                            <a href='https://www.linkedin.com/company/mgm-infra/' target='_blank'><img src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-256.png' className='follow-img' /></a>
                            <a href='https://www.instagram.com/mgminfra786/' target='_blank'><img src='https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-256.png' className='follow-img' /></a>
                            <a href='https://www.facebook.com/sameersohail143' target='_blank'><img src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-facebook_-256.png' className='follow-img'/></a>
                            <a href='https://wa.me/+919618670351' target='_blank'><img src='https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo-whatsapp-512.png' className='follow-img'/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-sec-cont'>
        <div className='bottom-layer'>
        <div className='home-main-bottom-section'>
                <div className='home-main-bottom-section-about-cont'>
                    <h1 className='home-main-bottom-section-about-head'>About Us</h1>
                    <hr className='hr' align="left"></hr>
                    <p className='home-main-bottom-section-para'>MGM Infra is a full-service contractor and industry leader, with the people, expertise, and financial strength to execute and deliver both large and small projects- successfully. We have earned our reputation as one of the most experienced, innovative, and quality-driven contractors india in the civil construction industry.</p>
                </div>
                <div>
                    <h1 className='home-main-bottom-section-about-head'>Menu</h1>
                    <hr className='hr' align="left"></hr>
                    <Link to='/about' className='home-main-bottom-section-para'><p>About Us</p></Link>
                    <Link to='/services' className='home-main-bottom-section-para'><p>Services</p></Link>
                    <Link to='/projects' className='home-main-bottom-section-para'><p>Projects</p></Link>
                    <Link to='/gallery' className='home-main-bottom-section-para'><p>Photo Gallery</p></Link>
                    <Link to='/contact' className='home-main-bottom-section-para'><p>Contact Us</p></Link>
                </div>
                <div className='bottom-sec-text-cont'>
                    <h1 className='home-main-bottom-section-about-head' >Area of Expertise</h1>
                    <hr className='hr' align="left"></hr>
                    <p className='home-main-bottom-section-para'>Desining</p>
                    <p className='home-main-bottom-section-para'>Construction of Residential Bulidings</p>
                    <p className='home-main-bottom-section-para'>Construction of Sport Facilities</p>
                </div>
                <div>
                    <h1 className='home-main-bottom-section-about-head'>Contact</h1>
                    <hr className='hr' align="left"></hr>
                    <p className='home-main-bottom-section-para'>Contact No: +919618670351</p>
                    <p className='home-main-bottom-section-para'>Email: <a href="mailto: mgminfra22@gmail.com">mgminfra22@gmail.com</a></p>
                </div>
                <div>
                    <h1 className='home-main-bottom-section-about-head'>Follow us</h1>
                    <hr className='hr' align="left"></hr>
                    <div className='contact-img-cont'>
                        <a href='https://www.linkedin.com/company/mgm-infra/' target='_blank'><img src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-256.png' className='follow-img' /></a>
                        <a href='https://www.instagram.com/mgminfra786/' target='_blank'><img src='https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-256.png' className='follow-img' /></a>
                        <a href='https://www.facebook.com/sameersohail143' target='_blank'><img src='https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-facebook_-256.png' className='follow-img' /></a>
                        <a href='https://wa.me/+919618670351' target='_blank'><img src='https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo-whatsapp-512.png' className='follow-img' /></a>
                    </div>
                </div>
            </div>
            <hr className='hr-bottom'></hr>
            <div className='bottom-credit-cont'>
                <p className='bottom-credit-para'>© 2023 MGM Infra Company - All Rights Reserved | Website By : <a href='https://github.com/mdsameersohail' target='_blank'>Md Sameer Sohail</a></p>
            </div>
            </div>
            </div>
            <a
              href="https://wa.me/+919618670351"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
            </>
        }
        </>
        )
    }
    
}

export default ContactUs