import {Switch, Route} from 'react-router-dom'

import NotFound from './components/NotFound'
import Home from './components/Home'
import ContactUs from './components/ContactUs'
import Services from './components/Services'
import About from './components/About'
import Projects from './components/Projects'
import PhotoGallery from './components/PhotoGallery'

import './App.css'

const App = () => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Route exact path='/about' component={About} />
    <Route exact path='/contact' component={ContactUs}/>
    <Route exact path="/services" component={Services} />
    <Route exact path="/projects" component={Projects}/>
    <Route exact path="/photogallery" component={PhotoGallery}/>
    <Route component={NotFound} />
  </Switch>
)

export default App

