import './index.css'
import Header from '../Header'
import BottomSection from '../BottomSection'
import servicesLable1 from '../../images/servicesLable1.jpg'
import {Component} from 'react'
import AOS from 'aos'
import {TailSpin} from 'react-loader-spinner'
import constrvideo from '../../images/constrvideo.mp4'


class Services extends Component{
    state={
        servicesLoading: true
      }

    componentDidMount(){
        setTimeout(() => {
          this.setState({ servicesLoading : !this.state.servicesLoading })
       }, 200);
    }
    
    loadingSpinner=()=>{
        return(
          <div className="products-loader-container">
                <TailSpin height="80" width="80" radius="9" color="green" ariaLabel="loading" />
            </div>
        )
    }
    render(){
        const{servicesLoading}=this.state
        AOS.init({duration:1000})
    return(
        <>
        <Header/>
        {servicesLoading? this.loadingSpinner() :
        <>
        <div className='service-main-cont'>
            <h1 className='service-main-heading'>Services</h1>
            <p className='service-main-para'>Quality work from dedicated professionals.</p>
            <hr className='hr' align="left"></hr>
            <div className='service-main-labels-cont'>
                <div className='service-main-top-cont'>
                    <p className='service-main-top-label-smallHead'>OUR SERVICE</p>
                    <h1 className='service-main-head'>Leading the industry in quality, service, and efficiency.</h1>
                    <div className='service-main-led-cont' data-aos='slide-up'>
                        <img src='https://cdn2.iconfinder.com/data/icons/interior-and-architectural-design/91/interior_architectural_design_01-256.png' className='service-main-label-img'/>
                        <div>
                            <h1 className='service-main-top-label-head'>Design</h1>
                            <p className='service-main-top-label-para'>We ensure the proper design for every project to build sustainable and quality construction</p>
                        </div>
                    </div>
                    <div className='service-main-led-cont' data-aos='slide-up'>
                        <img src='https://cdn1.iconfinder.com/data/icons/construction-collection-2/512/construction-33-256.png' className='service-main-label-img'/>
                        <div>
                            <h1 className='service-main-top-label-head'>Construction</h1>
                            <p className='service-main-top-label-para'>We execute and build the structure with high quality standards</p>
                        </div>
                    </div>
                    <div className='service-main-led-cont' data-aos='slide-up'>
                        <img src='https://cdn0.iconfinder.com/data/icons/construction-2-21/512/construction-industry-building-44-256.png' className='service-main-label-img'/>
                        <div>
                            <h1 className='service-main-top-label-head'>Maintenance</h1>
                            <p className='service-main-top-label-para'>We maintain facilities and systems operational and in good working order.</p>
                        </div>
                    </div>
                </div>
                <div className='service-label-img-cont'>
                    <img src='https://res.cloudinary.com/drn0l89xj/image/upload/v1685177718/mgminfra%20images/servicesLable1_jolrxx.jpg' className='service-label-img'/>
                </div>
            </div>
            <div id={"servicedesign"} className='service-design-main-cont'>
                <h1 className='service-design-head'>Design</h1>
                <hr className='hr-service' align="left"></hr>
                <p className='service-design-para'>Successful projects begin with diligent planning. The design process starts with an initial meeting to discuss the vision, logistics, and final project outcomes with the key decision makers and the creative experts on the commercial general contractor team. This should be a collaborative process that explores options and directions that ultimately lead to an amazing finished product. Together, the team will walk through architectural, physical and economic requirements of the project as well as code requirements. Without the proper design and planning, you cannot expect to have a successful construction project.</p>
                <p className='service-design-second-para'>We ensure the proper design and planning</p>
                <div className='service-design-card-main-cont'>
                    <div className='service-design-card-cont'>
                        <div className='service-design-card design-card1'></div>
                        <h1 className='service-design-card-head'>2D Drawings with Detailing</h1>
                    </div>
                    <div className='service-design-card-cont'>
                        <div className='service-design-card design-card2'></div>
                        <h1 className='service-design-card-head'>Architectural and Structural Drawings</h1>
                    </div>
                    <div className='service-design-card-cont'>
                        <div className='service-design-card design-card3'></div>
                        <h1 className='service-design-card-head'>3D Modelling and Rendering</h1>
                    </div>
                </div>
            </div>
            <div className='service-design-main-cont'>
                <h1 className='service-design-head'>Construction</h1>
                <hr className='hr-service' align="left"></hr>
                <p className='service-design-para'>We responsible for managing the construction projects. We are responsible for making the design of the project they are assigned whether it is a transport project, a water or sewerage system project or more. We plan, design and then test the structures before they are ready to be used by the public. 
                We don’t just think about the structure but also how the structure of the project will affect the surrounding. We produce an economical, safe and environmentally stable structure for a particular project. Every stage of the construction process has always been facilitated with high technology equipment to sure quality and perfection. 
                </p>
                <p className='service-design-second-para'>We execute and build the structure with high quality standards</p>
                <h1 className='constr-head'>The construction works we undertake</h1>
                <p className='constr-work-head'>1. Sport Facilities</p>
                <hr className='hr-constr' align="left"></hr>
                <p className='constr-para'>The scope of project undertaken features complete new build, complete or partial refurbishment, change of use and enhancement, and or re-surfacing. We have the ability to work to the most technically challenging of specifications, often in difficult site conditions, and deliver genuine end to end solutions with a real commitment to quality and service.
                All elements of the contract are controlled by the management team specifically selected for each project and formalised by a project organogram.
                </p>
                <p className='constr-para'>We are highly skilled and qualified for delivering a portfolio of significant sports facilities. Our project plan is holistic and details exactly how the contract will be realised.</p>
                <ul>
                    <li className='constr-para'>Preliminaries</li>
                    <li className='constr-para'>Excavation & Groundworks</li>
                    <li className='constr-para'>Drainage</li>
                    <li className='constr-para'>Sub-Base</li>
                    <li className='constr-para'>Surfacing and Shockpad System</li>
                    <li className='constr-para'>Pitch/Track Equipment</li>
                    <li className='constr-para'>Independent Post-Completion Testing e.g FIFA, FIH, World Rugby</li>
                    <li className='constr-para'>Floodlighting</li>
                    <li className='constr-para'>Fencing</li>
                    <li className='constr-para'>Ancillary Infrastructure</li>
                </ul>
                <div className='constr-video-cont'>
                    <video
                        className='constr-video'
                        playsInline
                        autoPlay
                        muted
                        loop
                        >
                    <source
                        src='https://res.cloudinary.com/drn0l89xj/video/upload/v1685177716/mgminfra%20images/constrvideo_scatmx.mp4'
                        type="video/mp4"
                        />
                    </video>
                </div>
                <p className='constr-work-head'>2. Residential Construction</p>
                <hr className='hr-constr' align="left"></hr>
                <p className='constr-para'>We undertake execution of Residential Civil Structural Concrete Construction Works right from foundation stage to the completion stage, which includes erection of form-work, steelwork, concreting. The final stage of job execution includes finishing of the concrete surfaces after de-shuttering, Laying and placing in position the infrastructure for electrical (low & high voltage), plumbing network. Our work for Structural Construction includes Foundations, Substructures and Super Structures.</p>
                <div className='constr-images-cont'>
                    <img src='https://5.imimg.com/data5/SELLER/Default/2022/7/WZ/NO/AX/36616475/house-construction-services-500x500.jpg' className='constr-images'/>
                    <img src='https://img.freepik.com/premium-photo/new-residential-house-contemporary-style-building-progress-construction-site_293060-4470.jpg' className='constr-images'/>
                    <img src='https://5.imimg.com/data5/SELLER/Default/2022/2/UT/MZ/DB/97981764/building-construction-service-1000x1000.jpg' className='constr-images'/>
                </div>
                <p className='constr-work-head margin-comm'>3. Commercial Complex Construction</p>
                <hr className='hr-constr' align="left"></hr>
                <p className='constr-para'>Commercial construction involves the construction of buildings and other structures used for commercial purposes. It includes office buildings, retail stores, warehouses, factories, and more. In commercial construction, various professionals are involved, from architects to engineers to contractors. The first step in commercial construction is usually to develop a plan for the project that will consider the client's specific needs, the site's size and location, and any regulatory requirements. Once the plan is complete, the construction team can begin working on erecting the actual structure. This entire process requires excellent coordination, collaboration, and a significant amount of resources. We designing, renovate and build the small scale, medium scale, large scale commercial structures.</p>
                <div className='constr-images-cont'>
                    <img src='https://www.aakashgroup.com/wp-content/uploads/2019/07/commercial-1-1-1000x500.jpg' className='constr-images'/>
                    <img src='https://s3da-design.com/wp-content/uploads/2020/05/Commercial-Construction-Process.jpg' className='constr-images'/>
                    <img src='https://www.constructconnect.com/hubfs/commercial%20construction.jpg' className='constr-images'/>
                </div>
            </div>
            <div className='service-design-main-cont'>
                <h1 className='service-design-head'>Maintenance</h1>
                <hr className='hr-service' align="left"></hr>
                <p className='service-design-para'>We takes care of the maintenance of Building repairs and maintenance services like whitewash & colour washing, painting works, doors& window repair works, flooring work, false ceiling, and aluminium partition work, water supply & sanitary work and other civil related work mainly includes works undertaken for maintaining proper condition of buildings. There is a form to report about the kind of faults. We maintain a record of servicing. Maintenance of the Day to Day Repairs, Annual Repairs, Special Repairs and Preventive Maintenance is carried out to avoid breakdown of buildings and occurrence of maintenance problems in buildings and services.</p>
                <div className='constr-images-cont'>
                    <img src='https://5.imimg.com/data5/SELLER/Default/2022/6/DT/PT/RN/4305289/external-plastering-500x500.jpg' className='constr-images'/>
                    <img src='https://t3.ftcdn.net/jpg/04/83/71/48/360_F_483714864_ev0fiKtDdKDSTvzIWqlx4TmQqYn5JL2W.jpg' className='constr-images'/>
                    <img src='https://www.jobapscloud.com/SCRUZ/SUP/Build%20Maint%20Work%20II.jpg' className='constr-images'/>
                </div>
            </div>
        </div>
        <BottomSection/>
        <a
              href="https://wa.me/+919618670351"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </>
        }
    </>
    )
    }
}

export default Services