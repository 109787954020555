import './index.css'
import AllProjects from '../AllProjects'
import SportsFacilities from '../SportsFacilities'
import ResidentialProjects from '../ResidentialProjects'
import CommercialProjects from '../CommercialProjects'
import {Component} from 'react'
import {TailSpin} from 'react-loader-spinner'
import Header from '../Header'
import BottomSection from '../BottomSection'


class Projects extends Component{
    state={
        showAll:true,
        sports:false,
        residential:false,
        commercial:false,
        loading: true
    }

    componentDidMount(){
        setTimeout(() => {
          this.setState({ loading : !this.state.loading })
       }, 200);
      }
    
      loadingSpinner=()=>{
        return(
          <div className="products-loader-container">
                <TailSpin height="80" width="80" radius="9" color="green" ariaLabel="loading" />
            </div>
        )
      }

    onShowAllClick=()=>{
        this.setState({showAll:true,residential:false,sports:false,commercial:false})
        document.getElementById('showall').classList.add('pro-header-active-button')
        document.getElementById('residential').classList.remove('pro-header-active-button')
        document.getElementById('sports').classList.remove('pro-header-active-button')
        document.getElementById('commercial').classList.remove('pro-header-active-button')
        document.getElementById('showall').classList.remove('pro-header-active-start')
    }

    onResidentialClick=()=>{
        this.setState({residential:true,showAll:false,sports:false,commercial:false})
        document.getElementById('residential').classList.add('pro-header-active-button')
        document.getElementById('sports').classList.remove('pro-header-active-button')
        document.getElementById('commercial').classList.remove('pro-header-active-button')
        document.getElementById('showall').classList.remove('pro-header-active-button')
        document.getElementById('showall').classList.remove('pro-header-active-start')
    }

    onSportsClick=()=>{
        this.setState({sports:true,showAll:false,residential:false,commercial:false})
        document.getElementById('sports').classList.add('pro-header-active-button')
        document.getElementById('commercial').classList.remove('pro-header-active-button')
        document.getElementById('showall').classList.remove('pro-header-active-button')
        document.getElementById('residential').classList.remove('pro-header-active-button')
        document.getElementById('showall').classList.remove('pro-header-active-start')
    }

    onCommercialClick=()=>{
        this.setState({commercial:true,showAll:false,residential:false,sports:false})
        document.getElementById('commercial').classList.add('pro-header-active-button')
        document.getElementById('residential').classList.remove('pro-header-active-button')
        document.getElementById('showall').classList.remove('pro-header-active-button')
        document.getElementById('sports').classList.remove('pro-header-active-button')
        document.getElementById('showall').classList.remove('pro-header-active-start')
    }

    render(){
        const {showAll,sports,residential,commercial,loading}=this.state
        return(
            <>
            <Header/>
            {loading? this.loadingSpinner() :
            <>
            <div className='pro-bg-main-cont'>
                <div className='pro-bg-main-layer'>
                <h1 className='pro-main-heading'>Projects</h1>
                <hr className='hr-pro' align="left"></hr>
                <div className='pro-header-main-cont'>
                    <div className='pro-header-button-cont'>
                        <button id ='showall' className='pro-header-button pro-header-active-start' onClick={this.onShowAllClick}>Show all</button>
                        <button id='sports'className='pro-header-button' onClick={this.onSportsClick}>Sports Facilities</button>
                        <button id='residential'className='pro-header-button' onClick={this.onResidentialClick}>Residential</button>
                        <button id='commercial'className='pro-header-button' onClick={this.onCommercialClick}>Commercial</button>
                    </div>
                </div>
                <div>
                    {showAll && <AllProjects/>}
                    {sports && <SportsFacilities/>}
                    {residential && <ResidentialProjects/>}
                    {commercial && <CommercialProjects/>}
                </div>
                </div>
            </div>
            <BottomSection/>
            <a
              href="https://wa.me/+919618670351"
              className="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
            </>
            }
        </>
        )
    }
}

export default Projects